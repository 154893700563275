@font-face {
  font-family: 'menu';
  src: url("../../fonts/menu.eot?cabx30");
  src: url("../../fonts/menu.eot?#iefixcabx30") format("embedded-opentype"), url("../../fonts/menu.ttf?cabx30") format("truetype"), url("../../fonts/menu.woff?cabx30") format("woff"), url("../../fonts/menu.svg?cabx30#menu") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* line 46, classic.scss */
.classic-menu {
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  font-size: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;
}
/* line 60, classic.scss */
.classic-menu.ready {
  visibility: visible;
  opacity: 1;
}
/* line 64, classic.scss */
.classic-menu.fixed {
  position: fixed;
  z-index: 99;
  -webkit-transition: .5s all;
  transition: .5s all;
}

.admin-bar .classic-menu.fixed{
  top: 32px;
}
.wp-admin-bar .classic-menu.fixed{
  top: 32px;
}
.vc_editor .classic-menu.mini {
    top: 0px;
}

/* line 69, classic.scss */
.classic-menu.fixed.mini.mini-active, .classic-menu.fixed.mini.mini-fullwidth-active {
  margin: 0;
  width: 100%;
  padding: 0 !important;
  // height: auto !important;
}

/* line 77, classic.scss */
.classic-menu.fixed.mini.mini-active > ul, .classic-menu.fixed.mini.mini-fullwidth-active > ul {
  background: transparent;
  padding: 0 !important;
}
/* line 82, classic.scss */
.classic-menu.fixed.mini.mini-active > ul > li.logo, .classic-menu.fixed.mini.mini-fullwidth-active > ul > li.logo {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
/* line 85, classic.scss */
.classic-menu.fixed.mini.mini-active > ul > li.logo.pull-top, .classic-menu.fixed.mini.mini-fullwidth-active > ul > li.logo.pull-top {
  margin-left: 15px !important;
  float: left;
  left: inherit;
  position: relative;
}
/* line 91, classic.scss */
.classic-menu.fixed.mini.mini-active > ul > li.logo img, .classic-menu.fixed.mini.mini-fullwidth-active > ul > li.logo img {
  max-height: 30px;
}
/* line 99, classic.scss */
.classic-menu.fixed.mini.mini-fullwidth-active > ul {
  max-width: inherit !important;
}
.classic-menu.mini > ul{
  height: auto!important;
}
.classic-menu.mini > ul > li{
  margin-bottom: auto!important;
  margin-top: auto !important;
}
/* line 105, classic.scss */
.classic-menu.relative {
  position: relative;
}
/* line 108, classic.scss */
.classic-menu.absolute {
  position: absolute;
  z-index: 99;
}
/* line 114, classic.scss */
.classic-menu * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none !important;
  text-decoration: none !important;
}
/* line 121, classic.scss */
.classic-menu a {
  display: inline-block;
  -webkit-transition: .5s all;
  transition: .5s all;
  white-space: nowrap;
}
/* line 125, classic.scss */
.classic-menu a:hover {
  /*color: #fff;
  background: #000;*/
}

/* line 133, classic.scss */
.classic-menu li.hotlink a:hover {
  background: transparent;
}

.classic-menu-hot-links{
  display: none;
}
.classic-menu:not(.responsive) .classic-menu-hot-links.hotlinkDesktopEnable, .classic-menu.responsive .classic-menu-hot-links.hotlinkMobileEnable{
  display: block;
}

.classic-menu:not(.responsive) {

  .logo{
    text-align: center;
    line-height: 0;
    vertical-align: middle;
    float: none;
    display: inline-block;
    a{
      width:100%;
      line-height: 0;
    }
  }
  .logo-mobile-only{
    display: none !important;
  }

  #menu-main-menu{
    position: relative;
    height: auto;
    max-height: 500px;
    font-size: 0;
    text-align: left;
    transition: .7s all;
    list-style:none;
    margin:0;
    padding:0;
    display: inline-block;
    
    
    
    li {
      position: relative;
      z-index: 10;
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      transition: .7s all;

      &:hover{
        z-index: 50;
      }
    }
    
    .sub-menu{
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      width: auto;
      
      li{
        display: block;
        text-align: left;
      }
      a{
        display: block;
      }
      
      .sub-menu{
        top: 0;
        left: 100%;
      }
      
      & .collision .sub-menu{
        left: auto;
        right: 100%;
      }
    }
    
    .menu-item-has-children:hover{
      & > .sub-menu{
        display: block;
      }
    }
  }
  .classic-menu-hot-links{
    transition: .7s all;
  }

  &.incontainer:not(.responsive), &.fullwidth:not(.responsive){
    .menu-container{
      width: 100%;
      margin:0 auto;
      padding: 0 15px;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: left;
      flex-wrap: wrap-reverse;

      &::after{
        content: '';
        display: block;
        float: none;
        clear: both;
      }
    }

    .classic-menu-hot-links{
      margin: 0 0 0 auto;
      justify-self: flex-end;
      padding-left: unset;
      padding-right: unset;
      text-align: right;
      z-index: 200;
      position: relative;
      li{
        display: inline-block;
        float: none!important;
      }
    }

    &.classic_menu_align-pull-left{
      .menu-container{
        justify-content: flex-start;
      }
    }
    &.classic_menu_align-pull-right{
      .menu-container{
        justify-content: flex-end;

      }
      .classic-menu-hot-links{
        margin-left: 0;
      }
      #menu-main-menu{
        margin-left: auto;
      }
    }
    &.classic_menu_align-pull-center{
      .menu-container{
        align-items: flex-end;
      }
      #menu-main-menu{
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        white-space: nowrap;
        width: 100%;
        text-align: center;
        position: relative;
      }
      .classic-menu-hot-links{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%); 
      }

      &.classic_menu_logo_align-pull-left{
        .logo{
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 200;
        }  
      }
      &.classic_menu_logo_align-pull-top{
        #menu-main-menu{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .classic-menu-hot-links{
          position: relative;
          transform:none;
          float: right; 
        }
      }
    }
    &.classic_menu_logo_align-pull-top:not(.mini){
      display: block;
      .menu-container{
        display: block;
      }
    }




  }
  &.mini-fullwidth-active.mini{
    .menu-container{
      max-width: none;
    }
    #menu-main-menu, .classic-menu-hot-links{
      padding-top: 0;
      padding-bottom: 0;
    }
    .logo{
      display: inline-block;
      float: none;
    }
  }
  &.mini-active.mini{
    
    #menu-main-menu, .classic-menu-hot-links{
      padding-top: 0;
      padding-bottom: 0;
    }
    .logo{
      display: inline-block;
      float: none;
      padding: 20px 0;
      img{
        max-height: 30px;
      }
    }
  }

} 


.classic-menu{

  &.classic_menu_logo_align-pull-top{
    .logo{
      width:100%;
    }

    &.classic_menu_align-pull-center{

      #menu-main-menu{
        margin-left: 0;
      }
    }

    .spacer{
      order: 2;
      margin-right: auto;
    }

    &.mini{
      .spacer{
        display: none;
      }

      .logo{
        width: auto;
        z-index: 2;
      }
    }

  }

  &.classic_menu_logo_align-pull-center.responsive{
    #menu-main-menu{
      .logo{
        display: none;;
      }
    }
  }

  &.classic_menu_logo_align-pull-center:not(.responsive){


    .menu-container>.logo{
      display: none;
    }

    #menu-main-menu{
      .logo{
        display: inline-block;
      }
    }

    &.classic_menu_align-pull-center{
      #menu-main-menu{
        margin-left: 0;
      }
    }

    .spacer{
      order: 2;
      margin-right: auto;
    }
  }


}

.classic-menu .hotlink a {
  padding-left: 10px!important;
  padding-right: 10px!important;
  // font-size: 18px!important;
}

.classic-menu.responsive {

  height: 60px;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  display: block;
}

.classic-menu.responsive .logo{
  position: absolute;
  height: 60px;
  top: 0;
  left: 0;
  padding: 0px;
  padding-left:15px;
  line-height: 0;
  display: table;
  z-index:2;
}
.classic-menu.responsive .logo a{
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.classic-menu.responsive .logo img{
  width: auto;
  height: auto;
  max-height: 60px;
  max-width: calc(100vw - 100px);
  padding: 5px;
}


/* line 341, classic.scss */
.classic-menu.responsive .responsive-header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 1.5em 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;
}
/* line 349, classic.scss */
.classic-menu.responsive .responsive-header a.sandwich-icon {
  float: right;
  font-size: 30px;
  line-height: 59px;
  padding: auto 10px;
  width: 40px;
  height: 40px;
  -webkit-transition: 0s all !important;
  transition: 0s all !important;
}
/* line 358, classic.scss */
.classic-menu.responsive .responsive-header a.sandwich-icon:before {
  font-family: menu;
  content: "\e602";
}

/* line 364, classic.scss */
.classic-menu.responsive .responsive-header a.sandwich-icon.opened {
  -webkit-transition: 0s all !important;
  transition: 0s all !important;
}
/* line 367, classic.scss */
.classic-menu.responsive .responsive-header a.sandwich-icon.opened:before {
  font-family: menu;
  content: "\e601";
}

.classic-menu.classic_menu_logo_align-pull-top.responsive .logo {
  width: auto;
}
/* line 390, classic.scss */
.classic-menu.responsive #menu-main-menu li.logo, .classic-menu.responsive #menu-responsive-menu li.logo{
  position: absolute;
  top: -60px;
  left: 15px;
  border: 0;
  max-height: 60px;
  margin-left: 0 !important;
  padding-top: 0 !important;
  width: calc(100% - 100px);
}
/* line 398, classic.scss */
.classic-menu.responsive #menu-main-menu li.logo a, .classic-menu.responsive #menu-responsive-menu li.logo a {
  margin: 0 auto !important;
  display: table-cell;
  vertical-align: middle;
  height: 60px;
  padding: 0;
}
/* line 403, classic.scss */
.classic-menu.responsive #menu-main-menu li.logo a img, .classic-menu.responsive #menu-responsive-menu li.logo a img {
  max-height: 50px;
  width: 100%;
  height: auto;
  display: block;
}

.classic-menu.responsive{
  z-index: 9000;
  #menu-main-menu, #menu-responsive-menu{
    li{
      font-size:36px;
      line-height:1em;
      color:#000;
      &.menu_small_item, &.menu_small_item li{
        font-size: 21px;
        line-height: 1em;
      }
      a{
        font-size:inherit;
        font-weight: inherit;
        color: inherit;
        line-height:inherit;
        font-family:inherit;
      }
    }
    .sub-menu{
      padding-left: 15px;
      width: 100%;
      li a{
        font-size:0.70em;
        background-color: unset;
        line-height: 1em;
      }
      li:first-of-type{
        margin-top: 10px;
      }
      li:last-of-type{
        margin-bottom: 10px;
      }
    }
  }
  #menu-main-menu, #menu-responsive-menu{
    padding-top: 60px;
  }
  #menu-main-menu, #menu-responsive-menu, .classic-menu-hot-links{
    padding-left:0px;
    padding-right:0px;
    >li, .languages-selector{
      padding-left: 20px;
    }
  }
  .classic-menu-hot-links{
    &[style="text-align: center"] li{
      padding-left: 0;
    }
    margin-top: 30px;
    a{
      font-size:24px;
      line-height: 1;
      color: #000;
    }
    li+li{
      padding-left:0;
    }
  }
  .menu-item{
    a{
      padding: 6px 0;
    }
    
    &:not(.menu_small_item) + .menu_small_item{
      margin-top:30px !important;
    }
  }
  ul:not(.sub-menu)>.menu-item.active>a{
    position: relative;
    &:before{
      content: "";
      width: 25px;
      height: 1px;
      display: block;
      position: absolute;
      left: -29px;
      background: #000;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .menu-responsive-bg{
    background: #fff;
    position: absolute;
    top:-60px;
    right:0;
    width:0;
    height: calc(100vh + 65px);
    opacity:0;
    z-index: -1;
    transition: 0.25s ease;
  }
  &.visible{
    .responsive-header{
      background-color: transparent;
      //border-bottom: 0;
    }
    a.sandwich-icon{
      color: #fff;
    }
    .menu-container{ 
      overflow-y: auto;
      height: calc(100vh - 60px);
      padding-top: 10px;
      padding-bottom: 30px;
    }
    .menu-responsive-bg{
      opacity:1;
      width: 100%;
    }
  }
  .hotlink{
    float: none;
    display: inline-block;

    &:first-of-type a{
      padding-left: 0 !important;
    }
  }
}


.admin-bar.continuousPlayer-enable .classic-menu.responsive .menu-container{
  height: calc(100vh - 46px);
}


.responsive .menu-container .languages-selector{
  li{
    opacity: 1 !important;
  }
  margin-top:30px;
  ul{
    padding: 0;
  }
  a{
    padding-right: 25px !important;
    padding-left: 0 !important;
  }
}



/*Responsice Menu Animation -------------------------------------------*/

/* Those variables values are micro-second(ms)*/
$timeItemTransition: 500;
$timeDelaybetweenItem: 75;
$delaySmallItem: 100;
$delayhotlink: 300;

$itemVerticalTransition: 100px;
/*-------------*/


.responsive.visible .menu-container{
  li{
    transition: #{$timeItemTransition+'ms'} ease;

    .sub-menu{
      transition: #{$timeItemTransition*2+'ms'} ease;
    }
  }
}
.responsive .menu-container{
  ul{
    overflow: hidden;
  }
  li{
    opacity: 0;
    position: relative;
    transition: 0;
    &:not(.active)>ul>li{
      opacity: 0;
    }
    .sub-menu{
      max-height: 1000px;
      transition: 0;
    }
    &:not(.active) .sub-menu{
      max-height: 0 !important;
    }
  }
  ul>li{
    top: $itemVerticalTransition;
  }
}
.responsive:not(.visible) .menu-container{
  width: 0;
}
.responsive.visible .menu-container{
  width: 100vw;
}
/*
.responsive:not(.visible) .menu-container, .responsive:not(.visible) .menu-responsive-bg{
  transition-delay: 0.7s;
}
*/
.responsive.visible .menu-container, .responsive .menu-responsive-bg{
  transition-delay: 0s;
}
.responsive.visible .menu-container ul>li{
  opacity: 1;
  top: 0;
}


/*animation*/
/*main items*/
@for $i from 1 through 30 {
  $delay: $timeDelaybetweenItem*$i+'ms';
  .responsive.visible .menu-container ul:not(.classic-menu-hot-links) li:not(.menu_small_item):nth-of-type(#{$i}){
	  transition-delay: #{$delay};
  }
}
/*small item*/
@for $i from 1 through 30 {
  $delay: $timeDelaybetweenItem*$i+$delaySmallItem+'ms';
  .responsive.visible .menu-container li.menu_small_item:nth-of-type(#{$i}){
	  transition-delay: #{$delay};
  }
}
/*sub menu item*/
@for $i from 1 through 30 {
  $delay: $timeDelaybetweenItem*$i+'ms';
  .responsive.visible .menu-container .sub-menu li:nth-of-type(#{$i}){
	  transition-delay: #{$delay};
  }
}
/*hotlink item*/
@for $i from 1 through 30 {
  $delay: $timeDelaybetweenItem*$i+$delayhotlink+'ms';
  .responsive.visible .menu-container ul.classic-menu-hot-links li:nth-of-type(#{$i}){
	  transition-delay: #{$delay};
  }
}
/*-------------*/